import "bridgetown-quick-search/dist"
import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import LazyLoad from 'vanilla-lazyload'

const lazyLoadInstance = new LazyLoad({
  // check out https://github.com/verlok/vanilla-lazyload for possible settings
  elements_selector: '.lazy',
  threshold: 300
})

import "$styles/index.css"
import "$styles/syntax-highlighting.css"

import "./main.js"

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"

console.info("Bridgetown is loaded!")

